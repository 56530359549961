import { FormEvent, useState } from "react"
import styled from "styled-components"

import { SectionNoiseDuration } from "src/components/MonitoringPresets/MonitoringPresetDialogs/NoiseThresholdsDialog/SectionNoiseDuration"
import { SectionNoiseThreshold } from "src/components/MonitoringPresets/MonitoringPresetDialogs/NoiseThresholdsDialog/SectionNoiseThreshold"
import { SectionQuietHours } from "src/components/MonitoringPresets/MonitoringPresetDialogs/NoiseThresholdsDialog/SectionQuietHours"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { usePatchNoiseMonitoringPreset } from "src/data/profileSettings/queries/monitoringPresetQueries"
import { TNoiseMonitoringPreset } from "src/data/profileSettings/types/monitoringPresetTypes"
import { useIsValueDirty } from "src/hooks/useIsValueDirty"
import { useTranslate } from "src/i18n/useTranslate"
import { DiscardChangesDialog } from "src/ui/Dialog/DiscardChangesDialog"
import { MDialog } from "src/ui/Dialog/MDialog"
import { MDivider } from "src/ui/Divider/Divider"
import { spacing } from "src/ui/spacing"

const FORM_ID = "noise-thresholds-form"

export function NoiseThreholdsDialog({
  open,
  ...props
}: {
  onClose: () => void
  settings: TNoiseMonitoringPreset
  open: boolean
}) {
  if (!open) return null
  return <NoiseThreholdsDialogOpen {...props} />
}

function NoiseThreholdsDialogOpen({
  onClose,
  settings,
}: {
  onClose: () => void
  settings: TNoiseMonitoringPreset
}) {
  const { orgId } = useOrganization()
  const { t, langKeys } = useTranslate()
  const [showConfirm, setShowConfirm] = useState(false)
  const patchNoiseMonitoringPreset = usePatchNoiseMonitoringPreset()

  const s = usePresetSettings(settings)

  function handleDiscardConfirmed() {
    setShowConfirm(false)
    onClose()
  }

  function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault()
    e.stopPropagation()
    patchNoiseMonitoringPreset.mutate(
      {
        path: { organization_id: orgId, profile_id: settings.id },
        body: s.toPreset(),
      },
      { onSuccess: onClose }
    )
  }

  function handleClose() {
    if (s.isDirty()) {
      setShowConfirm(true)
    } else {
      onClose()
    }
  }

  const error =
    patchNoiseMonitoringPreset.error?.status === 400
      ? t(langKeys.failed_something_went_wrong)
      : undefined
  const isLoading = patchNoiseMonitoringPreset.isLoading

  return (
    <MDialog
      open={true}
      onClose={handleClose}
      title={t(langKeys.presets_thresholds_and_duration_title)}
      description={t(langKeys.presets_thresholds_and_duration_description)}
      hideClose
      formId={FORM_ID}
      confirmLabel={t(langKeys.save)}
      loading={isLoading}
      error={error}
    >
      {showConfirm && (
        <DiscardChangesDialog
          open={showConfirm}
          onClose={() => {
            setShowConfirm(false)
          }}
          onConfirm={handleDiscardConfirmed}
        />
      )}

      <Form id={FORM_ID} onSubmit={handleSubmit}>
        <SectionNoiseThreshold
          indoorValue={s.indoorThreshold.value}
          setIndoorValue={s.indoorThreshold.setValue}
          outdoorValue={s.outdoorThreshold.value}
          setOutdoorValue={s.outdoorThreshold.setValue}
        />

        <MDivider />

        <SectionNoiseDuration
          value={s.noiseDuration.value}
          setValue={s.noiseDuration.setValue}
        />

        <MDivider />

        <SectionQuietHours
          enabled={s.quietHours.value}
          setEnabled={s.quietHours.setValue}
          startTime={s.quietHoursStartTime.value}
          setStartTime={s.quietHoursStartTime.setValue}
          endTime={s.quietHoursEndTime.value}
          setEndTime={s.quietHoursEndTime.setValue}
          indoorThreshold={s.quietHoursIndoorThreshold.value}
          setIndoorThreshold={s.quietHoursIndoorThreshold.setValue}
          outdoorThreshold={s.quietHoursOutdoorThreshold.value}
          setOutdoorThreshold={s.quietHoursOutdoorThreshold.setValue}
        />
      </Form>
    </MDialog>
  )
}

const Form = styled.form`
  display: grid;
  gap: ${spacing.L};
`

function usePresetSettings(settingsInitial: TNoiseMonitoringPreset) {
  const settings = {
    indoorThreshold: useIsValueDirty({
      initial: settingsInitial.indoor_noise_threshold?.threshold ?? 0,
    }),
    outdoorThreshold: useIsValueDirty({
      initial: settingsInitial.outdoor_noise_threshold?.threshold ?? 0,
    }),
    noiseDuration: useIsValueDirty({
      initial: settingsInitial.indoor_noise_threshold?.duration_seconds ?? 0,
    }),
    quietHours: useIsValueDirty({
      initial:
        settingsInitial.indoor_noise_threshold?.quiet_hours_enabled ?? false,
    }),
    quietHoursStartTime: useIsValueDirty({
      initial:
        settingsInitial.indoor_noise_threshold?.quiet_hours
          ?.start_time_of_day ?? "00:00",
    }),
    quietHoursEndTime: useIsValueDirty({
      initial:
        settingsInitial.indoor_noise_threshold?.quiet_hours?.end_time_of_day ??
        "00:00",
    }),
    quietHoursIndoorThreshold: useIsValueDirty({
      initial:
        settingsInitial.indoor_noise_threshold?.quiet_hours?.threshold ?? 0,
    }),
    quietHoursOutdoorThreshold: useIsValueDirty({
      initial:
        settingsInitial.outdoor_noise_threshold?.quiet_hours?.threshold ?? 0,
    }),
  } as const

  const isDirty = () => Object.values(settings).some((item) => item.isDirty)

  function toPreset(): Pick<
    TNoiseMonitoringPreset,
    "indoor_noise_threshold" | "outdoor_noise_threshold"
  > {
    const commonThresholds = {
      duration_seconds: settings.noiseDuration.value,
      quiet_hours_enabled: settings.quietHours.value,
    }
    const commonQuietHourThresholds = {
      start_time_of_day: settings.quietHoursStartTime.value,
      end_time_of_day: settings.quietHoursEndTime.value,
      duration_seconds: settings.noiseDuration.value,
    }

    return {
      indoor_noise_threshold: {
        ...commonThresholds,
        threshold: settings.indoorThreshold.value,
        quiet_hours: {
          ...commonQuietHourThresholds,
          threshold: settings.quietHoursIndoorThreshold.value,
        },
      },
      outdoor_noise_threshold: {
        ...commonThresholds,
        threshold: settings.outdoorThreshold.value,
        quiet_hours: {
          ...commonQuietHourThresholds,
          threshold: settings.quietHoursOutdoorThreshold.value,
        },
      },
    } as const
  }

  return { ...settings, isDirty, toPreset }
}
