import { useState } from "react"
import styled from "styled-components"

import { SearchFilter } from "src/components/Filter/SearchFilter"
import { useBulkAddRentalUnitsDialogParams } from "src/components/MonitoringPresets/MonitoringPresetDialogs/BulkAddRentalUnitsDialog/useBulkAddRentalUnitsDialogParams"
import { Pager } from "src/components/Pager/Pager"
import { HREF_MINUT_HELP_NOISE_PRESETS } from "src/constants/hrefs"
import { HOME_LIST_LIMIT } from "src/data/homes/logic/homeConstants"
import { THome } from "src/data/homes/types/homeTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import {
  useFetchHomes,
  usePatchHome,
  usePatchHomes,
} from "src/data/organizations/queries/homeQueries"
import { useTranslate } from "src/i18n/useTranslate"
import { MButton } from "src/ui/Button/MButton"
import ConfirmDialog from "src/ui/Dialog/ConfirmDialog"
import { MDialog } from "src/ui/Dialog/MDialog"
import { MDividerCss } from "src/ui/Divider/Divider"
import { LearnMore } from "src/ui/Link/LearnMore"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"
import { sessionStorageFactory } from "src/utils/storageUtil"

const PAGING_LIMIT = HOME_LIST_LIMIT

export function BulkAddRentalUnitsDialog({
  presetName,
  presetId,
  open,
  onClose,
}: {
  presetName: string
  presetId: string
  open: boolean
  onClose: () => void
}) {
  const { orgId } = useOrganization()
  const { t, langKeys } = useTranslate()
  const title = t(langKeys.presets_bulk_add_title, { presetName })

  const [showConfirm, setShowConfirm] = useState(false)

  const dialogParams = useBulkAddRentalUnitsDialogParams()
  const fetchHomes = useFetchHomes({
    orgId,
    filters: {
      limit: PAGING_LIMIT,
      offset: dialogParams.offset,
      name: dialogParams.search,
    },
  })

  const patchHomes = usePatchHomes()

  function handleClose() {
    setShowConfirm(false)
    patchHomes.reset()
    dialogParams.setOffset(0)
    dialogParams.setSearch("")
    undoStorage.clear()
    onClose()
  }

  function handleConfirmBulkAdd() {
    patchHomes.mutate(
      {
        body: { noise_profile_id: presetId },
        query: dialogParams.search ? { name: dialogParams.search } : undefined,
        path: { organization_id: orgId },
      },
      { onSuccess: handleClose }
    )
  }

  const matchingNbrUnits = fetchHomes.data?.paging.total_count ?? 0
  const currentUndos = undoStorage.get() ?? {}

  return (
    <MDialog
      title={title}
      open={open}
      hideClose
      showCancel={false}
      confirmLabel={t(langKeys.close)}
      onClose={handleClose}
      onConfirm={handleClose}
    >
      <FilterBar>
        <SearchFilter
          onChange={(n) => dialogParams.setSearch(n)}
          initialValue={dialogParams.search}
          placeholder={t(langKeys.search_for_home)}
        />

        <MButton variant="minimal" onClick={() => setShowConfirm(true)}>
          {dialogParams.search
            ? t(langKeys.presets_bulk_add_all_units_matching_search, {
                number: matchingNbrUnits,
              })
            : t(langKeys.presets_bulk_add_all_units, {
                number: matchingNbrUnits,
              })}
        </MButton>
      </FilterBar>

      <HomeListBox>
        {fetchHomes.data?.homes.map((home) => {
          const { previousPresetId } = currentUndos?.[home.home_id] ?? {}

          return (
            <ButtonRow key={home.home_id}>
              <MText variant="subtitle">{home.name}</MText>

              <AddButton
                presetId={presetId}
                homeId={home.home_id}
                homePresetId={home.noise_profile_id}
                storedPreviousPresetId={previousPresetId}
              />
            </ButtonRow>
          )
        })}
      </HomeListBox>

      <Pager
        limit={PAGING_LIMIT}
        offset={dialogParams.offset}
        setOffset={dialogParams.setOffset}
        totalCount={fetchHomes.data?.paging.total_count ?? 0}
      />

      <ConfirmDialog
        open={showConfirm}
        onClose={() => {
          setShowConfirm(false)
          patchHomes.reset()
        }}
        title={t(langKeys.presets_bulk_confirm_title, { name: presetName })}
        description={
          <>
            {t(langKeys.presets_bulk_confirm_description, {
              number: matchingNbrUnits,
              name: presetName,
            })}{" "}
            <LearnMore href={HREF_MINUT_HELP_NOISE_PRESETS} />
          </>
        }
        onConfirm={handleConfirmBulkAdd}
        loading={patchHomes.isLoading}
        error={patchHomes.isError && t(langKeys.failed_something_went_wrong)}
      />
    </MDialog>
  )
}

const FilterBar = styled.div`
  display: flex;
  justify-content: space-between;

  & > :first-child {
    width: 25ch;
  }

  gap: ${spacing.M};
  margin-bottom: ${spacing.L};
`
const HomeListBox = styled.div`
  & > :not(:last-child) {
    ${MDividerCss(spacing.L)};
  }
  margin-bottom: ${spacing.L};
`

const ButtonRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

function AddButton({
  presetId,
  homeId,
  homePresetId,
  storedPreviousPresetId,
}: {
  presetId: string
  homeId: THome["home_id"]
  homePresetId: THome["noise_profile_id"]
  storedPreviousPresetId: string | undefined
}) {
  const { orgId } = useOrganization()
  const { t, langKeys } = useTranslate()

  const patchHome = usePatchHome()

  function handleAddHome({
    mode,
    targetPresetId,
  }: {
    mode: "add" | "undo"
    targetPresetId: string
  }) {
    patchHome.mutate(
      { orgId, homeId, data: { noise_profile_id: targetPresetId } },
      {
        onSuccess() {
          const currentUndos = undoStorage.get() ?? {}

          if (mode === "undo") {
            // Undo successful; clear storage of related undo item
            delete currentUndos[homeId]
            undoStorage.set(currentUndos)
          } else {
            const undoItem: TUndoItem = {
              homeId,
              currentPresetId: targetPresetId,
              previousPresetId: homePresetId,
            }
            undoStorage.set({ ...currentUndos, [homeId]: undoItem })
          }
        },
      }
    )
  }

  const isLoading =
    patchHome.isLoading && patchHome.variables?.homeId === homeId

  // N.B: Note that the rendering order is important, so be careful if you need
  // to change the code below.

  if (storedPreviousPresetId) {
    return (
      <MButton
        size="small"
        variant="secondary"
        onClick={() =>
          handleAddHome({
            mode: "undo",
            targetPresetId: storedPreviousPresetId,
          })
        }
        loading={isLoading}
      >
        {t(langKeys.undo_action)}
      </MButton>
    )
  }

  if (homePresetId === presetId && !isLoading) {
    return (
      <MText variant="body" color="tertiary">
        {t(langKeys.presets_already_in_preset)}
      </MText>
    )
  }

  return (
    <MButton
      size="small"
      variant="subtle"
      onClick={() => handleAddHome({ mode: "add", targetPresetId: presetId })}
      loading={isLoading}
    >
      {t(langKeys.add)}
    </MButton>
  )
}

type TUndoItem = {
  homeId: string
  currentPresetId: string
  previousPresetId: string | undefined
}

const undoStorage = sessionStorageFactory<{ [key: string]: TUndoItem }>({
  key: "minut.noise.preset.undo",
})
