import { FormEvent, useState } from "react"
import styled from "styled-components"

import { PresetSection } from "src/components/MonitoringPresets/MonitoringPresetDialogs/PresetSection"
import { PresetSectionToggle } from "src/components/MonitoringPresets/MonitoringPresetDialogs/PresetSectionToggle"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { usePatchNoiseMonitoringPreset } from "src/data/profileSettings/queries/monitoringPresetQueries"
import { TNoiseMonitoringPreset } from "src/data/profileSettings/types/monitoringPresetTypes"
import { useIsValueDirty } from "src/hooks/useIsValueDirty"
import { useTranslate } from "src/i18n/useTranslate"
import { DiscardChangesDialog } from "src/ui/Dialog/DiscardChangesDialog"
import { MDialog } from "src/ui/Dialog/MDialog"
import { MDivider } from "src/ui/Divider/Divider"
import { MTextField } from "src/ui/MTextField/MTextField"
import { spacing } from "src/ui/spacing"

const FORM_ID = "security-alarm-form"

export function SecurityAlarmDialog({
  open,
  ...props
}: {
  onClose: () => void
  noisePreset: TNoiseMonitoringPreset
  open: boolean
}) {
  if (!open) return null
  return <SecurityAlarmDialogOpen {...props} />
}

function SecurityAlarmDialogOpen({
  onClose,
  noisePreset,
}: {
  onClose: () => void
  noisePreset: TNoiseMonitoringPreset
}) {
  const { orgId } = useOrganization()
  const { t, langKeys } = useTranslate()
  const [showConfirm, setShowConfirm] = useState(false)
  const patchNoiseMonitoringPreset = usePatchNoiseMonitoringPreset()

  const s = usePresetSettings(noisePreset)

  function handleDiscardConfirmed() {
    setShowConfirm(false)
    onClose()
  }

  function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault()
    e.stopPropagation()
    patchNoiseMonitoringPreset.mutate(
      {
        path: { organization_id: orgId, profile_id: noisePreset.id },
        body: s.toPreset(),
      },
      { onSuccess: onClose }
    )
  }

  function handleClose() {
    if (s.isDirty()) {
      setShowConfirm(true)
    } else {
      onClose()
    }
  }

  const error =
    patchNoiseMonitoringPreset.error?.status === 400
      ? t(langKeys.failed_something_went_wrong)
      : undefined
  const isLoading = patchNoiseMonitoringPreset.isLoading

  return (
    <MDialog
      open={true}
      onClose={handleClose}
      title={t(langKeys.presets_security_alarm_settings_title)}
      description={t(langKeys.presets_security_alarm_settings_body)}
      hideClose
      formId={FORM_ID}
      confirmLabel={t(langKeys.save)}
      loading={isLoading}
      error={error}
    >
      {showConfirm && (
        <DiscardChangesDialog
          open={showConfirm}
          onClose={() => {
            setShowConfirm(false)
          }}
          onConfirm={handleDiscardConfirmed}
        />
      )}

      <Form id={FORM_ID} onSubmit={handleSubmit}>
        <PresetSection title={t(langKeys.security_alarm_grace_period_title)}>
          <MTextField
            label={`${t(langKeys.security_alarm_grace_period_title)} (${t(langKeys.seconds).toLowerCase()})`}
            type="number"
            value={String(s.gracePeriod.value)}
            onChange={(v) => s.gracePeriod.setValue(Number(v))}
            min={0}
            max={60}
          />
        </PresetSection>

        <MDivider />

        <PresetSectionToggle
          title={t(langKeys.instantly_turn_on_alarm_title)}
          description={t(langKeys.instantly_turn_on_alarm_footer)}
          state={s.instantlyTurnOnAlarm.value}
          setState={s.instantlyTurnOnAlarm.setValue}
        />
      </Form>
    </MDialog>
  )
}

function usePresetSettings(noisePreset: TNoiseMonitoringPreset) {
  const settings = {
    gracePeriod: useIsValueDirty({
      initial: noisePreset.security_alarm.grace_period ?? 45,
    }),
    instantlyTurnOnAlarm: useIsValueDirty({
      initial: noisePreset.security_alarm.instantly_turn_on ?? false,
    }),
  } as const

  const isDirty = () => Object.values(settings).some((item) => item.isDirty)

  function toPreset(): Pick<TNoiseMonitoringPreset, "security_alarm"> {
    return {
      security_alarm: {
        grace_period: settings.gracePeriod.value,
        instantly_turn_on: settings.instantlyTurnOnAlarm.value,
      },
    } as const
  }

  return { ...settings, isDirty, toPreset }
}

const Form = styled.form`
  display: grid;
  gap: ${spacing.L};
`
